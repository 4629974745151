.w-richtext>:first-child {
    margin-top: 0;
}

/* Snippet gets rid of bottom margin on last element in any rich text*/
.w-richtext>:last-child,
.w-richtext ol li:last-child,
.w-richtext ul li:last-child {
    margin-bottom: 0;
}

.w-richtext>* {
    margin: 10px 0 10px 0;
}

.w-richtext ul li {
    display: list-item;
    list-style-position: inside;
    list-style-type: inherit;
}

.w-richtext:before,
.w-richtext:after {
    content: " ";
    grid-area: 1/1/2/2;
    display: table
}

.w-richtext:after {
    clear: both
}

.w-richtext img {
    width: 100%;
    height: auto;
}

.w-richtext[contenteditable=true]:before,
.w-richtext[contenteditable=true]:after {
    white-space: initial
}

.w-richtext ol,
.w-richtext ul {
    overflow: hidden
}

.w-richtext iframe {
    width: 100% !important;
    height: 600px;
}

.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after,
.w-richtext .w-richtext-figure-selected[data-rt-type=video] div:after,
.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div,
.w-richtext .w-richtext-figure-selected[data-rt-type=image] div {
    outline: 2px solid #2895f7
}

.w-richtext figure.w-richtext-figure-type-video>div:after,
.w-richtext figure[data-rt-type=video]>div:after {
    content: "";
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

.w-richtext figure {
    max-width: 100%;
    position: relative;
    margin: auto;
}

.w-richtext figure>div:before {
    cursor: default !important
}

.w-richtext figure img {
    width: 100%
}

.w-richtext figure figcaption.w-richtext-figcaption-placeholder {
    opacity: .6
}

.w-richtext figure div {
    color: transparent;
    font-size: 0
}

.w-richtext figure.w-richtext-figure-type-image,
.w-richtext figure[data-rt-type=image] {
    display: table
}

.w-richtext figure.w-richtext-figure-type-image>div,
.w-richtext figure[data-rt-type=image]>div {
    display: inline-block
}

.w-richtext figure.w-richtext-figure-type-image>figcaption,
.w-richtext figure[data-rt-type=image]>figcaption {
    caption-side: bottom;
    display: table-caption
}

.w-richtext figure.w-richtext-figure-type-video,
.w-richtext figure[data-rt-type=video] {
    width: 60%;
    height: 0
}

.w-richtext figure.w-richtext-figure-type-video iframe,
.w-richtext figure[data-rt-type=video] iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.w-richtext figure.w-richtext-figure-type-video>div,
.w-richtext figure[data-rt-type=video]>div {
    width: 100%
}

.w-richtext figure.w-richtext-align-center {
    clear: both;
    margin-left: auto;
    margin-right: auto
}

.w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image>div,
.w-richtext figure.w-richtext-align-center[data-rt-type=image]>div {
    max-width: 100%
}

.w-richtext figure.w-richtext-align-normal {
    clear: both
}

.w-richtext figure.w-richtext-align-fullwidth {
    width: 100%;
    max-width: 100%;
    text-align: center;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    display: block
}

.w-richtext figure.w-richtext-align-fullwidth>div {
    padding-bottom: inherit;
    display: inline-block
}

.w-richtext figure.w-richtext-align-fullwidth>figcaption {
    display: block
}

.w-richtext figure.w-richtext-align-floatleft {
    float: left;
    clear: none;
    margin-right: 15px
}

.w-richtext figure.w-richtext-align-floatright {
    float: right;
    clear: none;
    margin-left: 15px
}

@media screen and (max-width:991px) {

    .w-richtext img {
        width: 100%;
        height: auto;
    }

    .w-richtext iframe {
        height: auto;
        width: 100%;
    }
}

@media screen and (max-width:767px) {


    .w-richtext iframe {
        height: 380px;
    }
}

@media screen and (max-width:479px) {


    .w-richtext iframe {
        height: 300px;
    }
}