@font-face {
  font-family: 'TimesNewRomanBoldItalic';
  src: local('TimesNewRomanBoldItalic'), url(./assets/fonts/Times\ New\ Roman/TimesNewRomanBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url(./assets/fonts/SVN-GOTHAM/SVN-Gotham\ Black.otf) format('truetype');
}

@font-face {
  font-family: 'TimesNewRomanItalic';
  src: local('TimesNewRomanItalic'), url(./assets/fonts/Times\ New\ Roman/TimesNewRomanItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'TimesNewRoman';
  src: local('TimesNewRoman'), url(./assets/fonts/Times\ New\ Roman/TimesNewRoman.ttf) format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --white-color: #fff;
  --black-color: #000;
  --text-color: #333;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  /* font-size: 62.5%; */
  /* line-height: 1.6rem; */
  font-family: 'TimesNewRoman';
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  position: relative;
}

.preloading {
  position: fixed;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  -webkit-animation: fade-out 1s ease-out 5s both;
  animation: fade-out 1s ease-out 5s both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

a {
  text-decoration: none;
  color: #000;
}

img {
  max-width: 100%;
}

.scrolldown {
  --color: #fe5600;
  --sizeX: 30px;
  --sizeY: 50px;
  position: relative;
  width: var(--sizeX);
  height: var(--sizeY);
  margin-left: var(--sizeX / 2);
  border: calc(var(--sizeX) / 10) solid var(--color);
  border-radius: 50px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* margin-bottom: 16px; */
  cursor: pointer;
}

.scrolldown::before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: var(--color);
  border-radius: 100%;
  -webkit-animation: scrolldown-anim 2s infinite;
  animation: scrolldown-anim 2s infinite;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px -5px 3px 1px #2a547066;
  box-shadow: 0px -5px 3px 1px #2a547066;
}

@-webkit-keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }

  40% {
    opacity: 1;
    height: 10px;
  }

  80% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }

  100% {
    height: 3px;
    opacity: 0;
  }
}

@keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }

  40% {
    opacity: 1;
    height: 10px;
  }

  80% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }

  100% {
    height: 3px;
    opacity: 0;
  }
}

.chevrons {
  padding: 6px 0 0 0;
  margin-left: -3px;
  margin-top: 48px;
  width: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chevrondown {
  margin-top: -6px;
  position: relative;
  border: solid var(--color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.chevrondown:nth-child(odd) {
  -webkit-animation: pulse54012 500ms ease infinite alternate;
  animation: pulse54012 500ms ease infinite alternate;
}

.chevrondown:nth-child(even) {
  -webkit-animation: pulse54012 500ms ease infinite alternate 250ms;
  animation: pulse54012 500ms ease infinite alternate 250ms;
}

@-webkit-keyframes pulse54012 {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes pulse54012 {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}